import api from '@/base/utils/request';
import download from "@/base/utils/download";
import upload from "@/base/utils/upload4";

// 获取班级列表
export const ClassMembers = (data) => {
    return api({
        url: '/admin/school/classes/index',
        method: 'post',
        data
    })
}

// 导出班级列表
export const onExportMembers = (data) => {
    return download({
        url: '/admin/school/classes/export',
        method: 'post',
        data,
        download: true,
    })
}

// 添加班级
export const addClass = (data) => {
    return api({
        url: '/admin/school/classes/save',
        method: 'post',
        data,
    })
}

// 所有班级
export const AllClass = (data) => {
    return api({
        url: '/admin/school/classes/all',
        method: 'post',
        data,
    })
}

// 转移
export const move = (data) => {
    return api({
        url: '/admin/school/classes/move',
        method: 'post',
        data,
    })
}

// 删除
export const Delete = (data) => {
    return api({
        url: '/admin/school/classes/delete',
        method: 'post',
        data,
    })
}

// 所有毕业年份
export const entranceYears = (data) => {
    return api({
        url: '/admin/school/classes/entranceYears',
        method: 'post',
        data,
    })
}

// 所有批量导入
export const importClass = data => {
    return upload({
        url: '/admin/school/classes/importClass',
        typeArr: ["file"],
        data
    })
}